import React, { useState, useEffect } from 'react';
import AnnouncementList from './AnnouncementList';
import { get } from '../../helper/fetch';

export interface Announcement {
  id: number
  title: string
  content_html: string
  content_delta: string
  created_at: string
  updated_at: string
}

export interface AnnouncementWithAuthor extends Announcement {
  author: {
    id: string;
    name: string;
  }
}

const AnnouncementManager: React.FC = () => {
  const [announcements, setAnnouncements] = useState<AnnouncementWithAuthor[]>([])
  const [error, setError] = useState<string | null>(null);

  function getAnnouncements() {
    get('/announcements')
      .then((resp) => {
        if (resp) {
          setAnnouncements(resp.announcements);
        } else {
          setError(resp.error);
        }
      });
  }

  useEffect(() => {
    getAnnouncements();
  }, [])


  return (
    <div className="container mx-auto p-4 space-y-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center">Admin team announcements</h1>

      {error && <div className="text-red-500">{error}</div>}
      {announcements.length > 0 && <AnnouncementList
        announcements={announcements}
      />
      }
    </div>
  )
}

export default AnnouncementManager;