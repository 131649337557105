import React from 'react';

import { Announcement, AnnouncementWithAuthor } from './AnnouncementManager'
import IndividualCard from './IndividualCard';
type Props = {
  announcements: AnnouncementWithAuthor[]

}

const AnnouncementList: React.FC<Props> = ({ announcements }) => {
  return (
    <div className="space-y-4">
      {announcements.map((announcement) => (
        <IndividualCard key={announcement.id} announcement={announcement} />
      ))}
    </div>
  );
}

export default AnnouncementList;
