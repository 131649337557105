export type Person = {
  "id": string,
  "created_at": string,
  "updated_at": string,
  "is_admin": boolean,
  "username": string,
  "email": string,
  "given_name": string,
  "family_name": string,
  "display_name": string,
  "phone_number": string,
  "deleted": boolean,
  "role": 1 | 2 | 0,
  "referral_code"?: string,
  "referrer"?: string,
  "referral_used"?: boolean
}

export type Notes = {
  author_id: string;
  date_created: string;
  content: string;
  author: Person;
}

export type Property = {
  "id": number,
  "name": string,
  "color"?: string,
  "size": number,
  "number_of_rooms": number,
  "number_of_bathrooms": number,
  "entrance_info"?: string,
  "address"?: string,
  "parking_info"?: string,
  "owner_id"?: string,
  "apartment_number"?: string,
  "province"?: string,
  "town"?: string,
  "size_includes_basement": boolean,
  "other_info"?: string
}

export type Extra = {
  id: number;
  name: string;
  price: string | number;
};

export type Receipt = {
  "taxes": number,
  "total": number,
  "wePay": number,
  "subTotal": number,
  "clientPays": number,
  "cleanerGets": number,
  "couponWePay": number,
  "couponCovers": number,
  "taxesDetails": {
    "gst": number | string,
    "hst": number | string,
    "pst": number | string,
    "provinceIdentifier": "AB" | 'BC' | 'ON'
  },
  "serviceCharge": number,
  "totalFee": number,
};

export type Booking = {
  "id": number,
  transaction_id?: number,
  created_at: string,
  "updated_at": string,
  "cleaning_supplies": boolean,
  "same_day_cleaning": boolean,
  extras: Extra[];
  "cancelled": boolean,
  "coupon_code": string,
  "property_id": number,
  "recurrance"?: string,
  "previous_booking_id"?: number,
  "next_booking_id"?: number,
  "paid": boolean,
  "client": string,
  "cleaner": string,
  "conversation_id": number,
  status?: string | 'confirmed' | 'requested' | 'rejected' | 'paid' | 'accepted' | 'cancelled' | 'canceled',
  "card": string,
  "stripe_payment": string,
  "start_hour": number,
  "start_minute": number,
  "end_hour": number,
  "end_minute": number,
  "booking_date": string,
  "started_at"?: string,
  "stopped_at"?: string,
  "send_receipt": boolean,
  "taxes"?: any,
  "can_extend_hours": boolean,
  "notified": boolean,
  "receipt": Receipt,
  "bookingCleaner": Person,
  "bookingClient": Person,
  "bookingProperty": Property,
};

export type Listing = {
  id: number,
  open: boolean,
  availability: any[]
};

export type Cleaner = {
  id: string;
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  phoneNumber: string;
  joined: string;
  connectedAccount?: string;
  listing: {
    id: number;
    open: boolean;
    price: number;
    displayName: string;
    description: string;
    description_html: string;
    description_delta: string;
    years_of_experience: number;
    coordinates: {
      north: number;
      south: number;
      west: number;
      east: number;
    },
    availability: {
      monday: {
        from: string;
        till: string;
      },
      tuesday: {
        from: string;
        till: string;
      },
      wednesday: {
        from: string;
        till: string;
      },
      thursday: {
        from: string;
        till: string;
      },
      friday: {
        from: string;
        till: string;
      },
      saturday: {
        from: string;
        till: string;
      },
      sunday: {
        from: string;
        till: string;
      }
    },
    ratings: {
      quality: number;
      reliability: number;
      communication: number;
    },
    reviews: {
      text: string;
      author_name: string;
    }[],
    complaints_count: number;
    tier: {
      id: number;
      name: string;
      min_price: number;
      max_price: number;
    };
  };
}

export type CheckListProps = {
  name: string;
  items: {
    id: number;
    description: string;
  }[]
};

export const generalCleanChecklist: CheckListProps[] = [
  {
    name: "Kitchen",
    items: [
      { id: 1, description: "Cabinet exteriors and handles wiped where needed or mostly touched" },
      { id: 2, description: 'Sinks and backsplash washed' },
      { id: 3, description: 'Countertops and shelves dusted and/or wiped' },
      { id: 4, description: 'Clean the exteriors of the fridge, stove, oven, microwave, range hood, and dishwasher' },
      { id: 5, description: 'Small appliances wiped (microwave, toaster, coffee maker)' },
      { id: 6, description: 'All surfaces dusted' },
      { id: 7, description: 'Baseboards dusted and/or wiped' },
      { id: 8, description: 'Floor vacuumed and washed' },
      { id: 9, description: 'Organize the items on the countertops' },
      { id: 10, description: 'Light switches wiped' },
      { id: 11, description: 'Dry all surfaces' },
      { id: 12, description: 'Garbage and recycling bins emptied' },
    ]
  },
  {
    name: "Bathrooms",
    items: [
      { id: 1, description: 'Sink cleaned and sanitized' },
      { id: 2, description: 'Stainless steel/chrome areas shined, and fingerprints cleaned. Always dry after cleaning' },
      {
        id: 3,
        description: 'Bathtub, shower walls and glass doors cleaned and sanitized (Do NOT use toilet bowl cleaner ever)'
      },
      { id: 4, description: 'Tiles to be well cleaned/scrubbed' },
      { id: 5, description: 'Toilets cleaned and sanitized inside and out' },
      { id: 6, description: 'Mirrors cleaned' },
      { id: 7, description: 'Cabinet exteriors' },
      { id: 8, description: 'Countertops and shelves dusted and/or wiped' },
      { id: 9, description: 'All surfaces dusted' },
      { id: 10, description: 'Baseboards dusted and/or wiped' },
      { id: 11, description: 'Light switches wiped' },
    ]
  },
  {
    name: "Bedrooms",
    items: [
      { id: 1, description: 'All surfaces dusted' },
      { id: 2, description: 'Closet/dresser exteriors cleaned' },
      { id: 3, description: 'Mirrors cleaned' },
      { id: 4, description: 'DO NOT clean TV or computer screens. Only dust the frame and the areas around' },
      { id: 5, description: 'Carpet and area rugs vacuumed' },
      { id: 6, description: 'Hard surface floors vacuumed and damp-mopped' },
      { id: 7, description: 'Windowsills and window tracks cleaned' },
      { id: 8, description: 'Baseboards dusted and/or wiped' },
      { id: 9, description: 'Organize items and fold clothes & blankets on the bed or chair' },
      { id: 10, description: 'Bed made and extra blankets/pillows organized' },
      { id: 11, description: 'Garbage and recycling bins emptied' },
    ]
  },
  {
    name: "Other Living Areas",
    items: [
      { id: 1, description: 'All surfaces dusted' },
      {
        id: 2,
        description: 'Organize items. Fold blankets neatly on the couch. Place couch pillows nicely on the couch. Organize items on the coffee table'
      },
      { id: 3, description: 'Closet/dresser exteriors cleaned' },
      { id: 4, description: 'Mirrors cleaned' },
      { id: 5, description: 'Carpet and area rugs vacuumed' },
      { id: 6, description: 'Hard surface floors vacuumed and damp-mopped' },
      { id: 7, description: 'DO NOT clean TV or computer screens. Only dust the frame and the areas around' },
      { id: 8, description: 'Banisters and stair railing cleaned' },
      { id: 9, description: 'Clean the exteriors of the laundry washer and the dryer' },
      { id: 10, description: 'Baseboards dusted and/or wiped' },
      { id: 11, description: 'Light switches and the cover wiped' },
      { id: 12, description: 'Sweep off balcony or patio if requested' },
      { id: 13, description: 'Garbage and recycling bins emptied' },
    ]
  }
];

export const moveOutCleanChecklist: CheckListProps[] = [
  {
    name: "Kitchen",
    items: [
      { id: 1, description: 'Cabinet/cupboards exteriors and interiors cleaned' },
      { id: 2, description: 'Countertops and shelves dusted and/or wiped' },
      { id: 3, description: 'Sinks and backsplash washed' },
      {
        id: 4,
        description: 'Pull the fridge out of the wall and wash the floor underneath, vacuum dust and hair, clean inside of the fridge and outside including the handles and the top'
      },
      {
        id: 5,
        description: 'Pull the stove out of the wall and wash the floor underneath. Clean inside and outside. Clean oven racks inside. Clean stovetop/burners'
      },
      { id: 6, description: 'Clean microwave inside and outside' },
      { id: 7, description: 'Clean the hood above the stove (dusting the outside and degreasing the metal side)' },
      {
        id: 8,
        description: 'Wipe dishwasher exterior. Open the dishwasher and clean alongside of the door and clean any'
      },
      { id: 9, description: 'significant food residue or debris inside' },
      { id: 10, description: 'Small appliances wiped (microwave, toaster, coffee maker)' },
      { id: 11, description: 'Dust and clean light fixtures' },
      { id: 12, description: 'Windowsills and window tracks cleaned' },
      { id: 13, description: 'Baseboards cleaned' },
      { id: 14, description: 'Doorknobs, door frames and doors wiped' },
      { id: 15, description: 'Spot wall washing - marks on walls cleaned' },
      { id: 16, description: 'Light switches wiped' },
      { id: 17, description: 'Floor vacuumed and washed' },
      { id: 18, description: 'Garbage and recycling bins emptied' },
    ]
  },
  {
    name: "Bathrooms",
    items: [
      { id: 1, description: 'Sink cleaned and sanitized' },
      { id: 2, description: 'Stainless steel/chrome areas shined, and fingerprints cleaned. Always dry after cleaning' },
      {
        id: 3,
        description: 'Bathtub, shower walls and glass doors cleaned and sanitized (Do NOT use toilet bowl cleaner ever)'
      },
      { id: 4, description: 'Tiles to be well cleaned/scrubbed' },
      { id: 5, description: 'Toilets cleaned and sanitized inside and out' },
      { id: 6, description: 'Mirrors cleaned' },
      { id: 7, description: 'Cabinet exteriors and interiors cleaned' },
      { id: 8, description: 'Countertops and shelves dusted and/or wiped (No vinegar for countertops, use soap)' },
      { id: 9, description: 'Windowsills and window tracks cleaned' },
      { id: 10, description: 'All surfaces dusted' },
      { id: 11, description: 'Baseboards cleaned' },
      { id: 12, description: 'Doorknobs, door frames and doors wiped' },
      { id: 13, description: 'Light switches wiped' },
      { id: 14, description: 'Marks on walls and baseboards cleaned' },
      { id: 15, description: 'Floor vacuumed and washed' },
      { id: 16, description: 'Dry all surfaces' },
      { id: 17, description: 'Garbage and recycling bins emptied' },
    ]
  },
  {
    name: "Bedrooms",
    items: [
      { id: 1, description: 'All surfaces dusted' },
      { id: 2, description: 'Closet/dresser exteriors and interiors cleaned' },
      { id: 3, description: 'Mirrors cleaned and streak free' },
      { id: 4, description: 'DO NOT clean TV or computer screens. Only dust the frame and the areas around' },
      { id: 5, description: 'Spot wall washing - Marks on walls and baseboards cleaned' },
      { id: 6, description: 'Ceiling fans and light fixtures dusted' },
      { id: 7, description: 'Doorknobs, door frames and doors wiped' },
      { id: 8, description: 'Windowsills and window tracks cleaned' },
      { id: 9, description: 'Baseboards dusted and/or wiped' },
      { id: 10, description: 'Clean heating unit cover and cold air registers' },
      { id: 11, description: 'Light switches wiped' },
      { id: 12, description: 'Bed made and extra blankets/pillows organized' },
      { id: 13, description: 'Carpet and area rugs vacuumed' },
      { id: 14, description: 'Hard surface floors vacuumed and damp-mopped' },
      { id: 15, description: 'Garbage and recycling bins emptied' },
    ]
  },
  {
    name: "Other Living Areas",
    items: [
      { id: 1, description: 'All surfaces dusted' },
      { id: 2, description: 'Closet exteriors and interiors cleaned' },
      { id: 3, description: 'Mirrors cleaned' },
      { id: 4, description: 'Banisters and stair railing cleaned' },
      { id: 5, description: 'Spot wall washing - Marks on walls and baseboards cleaned' },
      { id: 6, description: 'Clean the exteriors of the laundry washer and the dryer. Clean out any left items (lint, piece of clothing, etc) from the washer and the dryer. Empty the dryer filter' },
      { id: 7, description: 'Windowsills and window tracks cleaned (indoor only)' },
      { id: 8, description: 'DO NOT clean TV or computer screens. Only dust the frame and the areas around' },
      { id: 9, description: 'Baseboards dusted and/or wiped' },
      { id: 10, description: 'Clean heating unit cover' },
      { id: 11, description: 'Doorknobs, door frames and doors cleaned' },
      { id: 12, description: 'Light switches and the cover wiped' },
      { id: 13, description: 'Light fixtures dusted' },
      { id: 14, description: 'Carpet and area rugs vacuumed' },
      { id: 15, description: 'Hard surface floors vacuumed and damp-mopped' },
      { id: 16, description: 'Sweep off balcony or patio if requested' },
      { id: 17, description: 'Garbage and recycling bins emptied' },
    ]
  }
];

export const airbnbCleanChecklist: CheckListProps[] = [
  {
    name: "Kitchen",
    items: [
      { id: 1, description: 'Countertops and shelves dusted and/or wiped (No vinegar and water for countertops, please use dish soap and water)' },
      { id: 2, description: 'Sinks and backsplash washed' },
      { id: 3, description: 'Wipe down all surfaces including the fridge, stove, oven, microwave, range hood, and dishwasher –' },
      { id: 4, description: 'remove any food or fingerprints' },
      { id: 5, description: 'Discard remaining food from the fridge and the freezer' },
      { id: 6, description: 'Small appliances wiped (microwave, toaster, coffee maker)' },
      { id: 7, description: 'Take out the used coffee ground from the coffee maker and clean/ dry' },
      { id: 8, description: 'Make sure dishwasher is empty and clean dishes are put away' },
      { id: 9, description: 'Put away pots and pans' },
      { id: 10, description: 'All surfaces dusted' },
      { id: 11, description: 'Fill hand soap and dish soap if they are low suing the supplies provided' },
      { id: 12, description: 'Floor vacuumed and mopped' },
      { id: 13, description: 'Organize the items on the countertops' },
      { id: 14, description: 'Light switches wiped' },
      { id: 15, description: 'Empty the compost bin and wipe' },
      { id: 16, description: 'Garbage and recycling bins emptied + new garbage bag lined in the garbage bin' },
    ]
  },
  {
    name: "Bathrooms",
    items: [
      { id: 1, description: 'Sink cleaned and sanitized' },
      { id: 2, description: 'Quick clean to make sure all dirt, hair, fingerprint is removed' },
      { id: 3, description: 'Toilets cleaned and sanitized inside and out (please don’t tap on the toilet bowl with the brush as it could cause a crack/ please make sure the toilet cleaner does not drip on the floor as it could cause damage)' },
      { id: 4, description: 'Mirrors cleaned' },
      { id: 5, description: 'Countertops and shelves dusted and/or wiped (No vinegar and water for countertops, please use dish soap and water)' },
      { id: 6, description: 'Refill soap, shampoo, hair conditioner if less than half full using the supplies provided' },
      { id: 7, description: 'Extra toilet rolls in the bathrooms and neatly fold the end of the toilet paper' },
      { id: 8, description: 'Light switches wiped' },
      { id: 9, description: 'Clean towels set up (nicely folded/ hotel style)' },
      { id: 10, description: 'Floor vacuumed and washed' },
      { id: 11, description: 'Garbage and recycling bins emptied + new garbage bag lined in the garbage bin' },
    ]
  },
  {
    name: "Bedrooms",
    items: [
      { id: 1, description: 'All surfaces dusted' },
      { id: 2, description: 'Wash and dry all dirty linens & towels' },
      { id: 3, description: 'Make beds with the clean linens and have the sheets nicely tucked in' },
      { id: 4, description: 'Mirrors cleaned' },
      { id: 5, description: 'DO NOT clean TV or computer screens. Only dust the frame and the areas around' },
      { id: 6, description: 'Set up the room nicely with the blankets and pillows placed nicely' },
      { id: 7, description: 'Carpet and area rugs vacuumed' },
      { id: 8, description: 'Hard surface floors vacuumed and damp-mopped' },
      { id: 9, description: 'Garbage and recycling bins emptied + new garbage bag lined in the garbage bin' },
    ]
  },
  {
    name: "Other Living Areas",
    items: [
      { id: 1, description: 'All surfaces dusted' },
      { id: 2, description: 'Organize items. Fold blankets neatly on the couch. Place couch pillows nicely on the couch. Organize items on the coffee table' },
      { id: 3, description: 'If there is a futon in the living area, please open it to make sure no left linen or towel left in there. Put the futon together into the couch shape (not into a bed)' },
      { id: 4, description: 'Mirrors cleaned' },
      { id: 5, description: 'If there is a fireplace, clean out and re-stock wood' },
      { id: 6, description: 'DO NOT clean TV or computer screens. Only dust the frame and the areas around' },
      { id: 7, description: 'Light switches and the cover wiped' },
      { id: 8, description: 'Carpet and area rugs vacuumed' },
      { id: 9, description: 'Hard surface floors vacuumed and damp-mopped' },
      { id: 10, description: 'Sweep off balcony or patio if requested' },
      { id: 11, description: 'Make sure the BBQ is covered if applicable' },
      { id: 12, description: 'Garbage and recycling bins emptied + new garbage bag lined in the garbage bin' },
    ]
  }
];