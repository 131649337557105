import React, {useState} from 'react';
import {GoogleMap, LoadScript, Rectangle} from '@react-google-maps/api';

//TODO: introduce prop types please.

const CleanerMap = ({north, south, east, west, onSave}) => {
  const [rectangle, setRectangle] = useState(null);

  const saveFunction = () => {
    if (rectangle) {
      const bounds = rectangle.getBounds();
      if (bounds) {
        /**
         set north = 51.0798
         south = 50.8806
         east = -113.88
         west = -114.103
         * */
        const coords = {
          north: bounds.getNorthEast().lat(),
          east: bounds.getNorthEast().lng(),
          south: bounds.getSouthWest().lat(),
          west: bounds.getSouthWest().lng()
        };
        onSave(coords);
      }
    }
  }

  return(
      <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM"
      >
        <GoogleMap mapContainerClassName={"min-h-full"}
                   id='cleaner-working-map'
                   mapContainerStyle={{
                     height: "100%",
                     width: "100%"
                   }}
                   zoom={9}
                   center={{
                     lat: south + (north-south)/2,
                     lng: east + (west-east)/2}
                   }
                   options={
                     {
                       fullscreenControl: true,
                       mapTypeControl: false,
                       streetViewControl: false
                     }
                   }
        >
          <Rectangle
              draggable={true}
              editable={true}
              onLoad={setRectangle}
              onBoundsChanged={()=>{}}
              bounds={{
                north: north,
                south: south,
                east: east,
                west: west,
              }}
          />
        </GoogleMap>
        <div className={"mt-3 flex items-center justify-between"}>
          <button
            type="submit"
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-hsgreen hover:bg-hsgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hsgreen"
            onClick={saveFunction}
          >
            Save
          </button>
        </div>
      </LoadScript>
  )
}

export default CleanerMap;